import { ref } from "vue";
import CouponService from "@/services/coupon.service";

// 類別 * 1 - 餐飲 * 0 - 零售
export const useCartCoupon = (kind) => {
  const list = ref([]);
  const isLoading = ref(false);

  const getList = (kind) => {
    return CouponService.getCartCoupon(kind);
  };

  getList(kind).then((res) => {
    if (res?.data?.length) {
      list.value.push(...res.data);
    }
  });

  return {
    list,
    isLoading,
  };
};
