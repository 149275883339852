<template>
  <el-drawer
    v-model="localOpen"
    :with-header="false"
    direction="btt"
    custom-class="container select-coupon"
  >
    <content-title title="請選擇優惠券" class="mb-3" />
    <div class="select-coupon-content">
      <template v-if="list.length">
        <div class="select-coupon-radio" v-for="item in list" :key="item.id">
          <el-radio size="large" v-model="radioValue" :label="item.id" />
          <coupon-item class="select-coupon-item" :item="item" />
        </div>
      </template>
      <template v-else>
        <no-info-block info="目前尚無可用優惠券" style="padding-bottom: 16px" />
      </template>
    </div>
    <div class="row no-gutters justify-content-center">
      <div class="col px-3">
        <button
          type="button"
          class="btn bg-dark btn-block shadow text-white"
          @click="cancel"
        >
          取消
        </button>
      </div>
      <div class="col px-3">
        <button
          type="button"
          class="btn btn-block btn-main shadow text-white"
          @click="confirm"
        >
          確認
        </button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { computed, ref } from "vue";
import CouponItem from "@/components/CouponItem.vue";
import ContentTitle from "@/components/ContentTitle.vue";
import NoInfoBlock from "@/components/NoInfoBlock.vue";
import { useCartCoupon } from "@/hook/coupon";
export default {
  components: {
    CouponItem,
    ContentTitle,
    NoInfoBlock,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    kind: {
      type: Number,
    },
    currentCoupon: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:isOpen", "update:currentCoupon"],
  setup(props, { emit }) {
    const localOpen = computed({
      get() {
        return props.isOpen;
      },
      set(newValue) {
        emit("update:isOpen", newValue);
      },
    });

    const radioValue = ref();

    const { list } = useCartCoupon(props.kind);

    const cancel = () => {
      radioValue.value = undefined;
      emit("update:isOpen", false);
    };

    const confirm = () => {
      emit(
        "update:currentCoupon",
        list.value.find((item) => item.id === radioValue.value)
      );
      radioValue.value = undefined;
      emit("update:isOpen", false);
    };

    return {
      localOpen,
      list,
      radioValue,
      cancel,
      confirm,
    };
  },
};
</script>

