<template>
  <div>
    <div class="top-bar" v-if="currentPrice !== undefined">
      <span class="left">
        <img src="@/assets/images/coupon-icon.svg" alt="" />
        優惠券
      </span>
      <span class="right" @click="handleSelectOpen">
        <span>
          {{
            Object.keys(currentCoupon).length !== 0
              ? "您已選擇一張優惠券"
              : "查看可用優惠券"
          }}
        </span>
        <span class="right-icon"></span>
      </span>
    </div>
    <div :class="{ 'cart-coupon-content': currentPrice !== undefined }">
      <div class="cart-coupon-block" v-if="Object.keys(currentCoupon).length !== 0">
        <template v-if="Object.keys(currentCoupon).length !== 0">
          <coupon-item class="cart-coupon-item" :item="currentCoupon" />
        </template>
      </div>
      <div class="container" v-if="Object.keys(currentCoupon).length !== 0">
        <div
          class="row text-right ponit-block"
          style="font-size: medium"
          :class="{ 'font-weight-bold': currentPrice !== undefined }"
        >
          <div class="col-12 p-2" v-if="currentCoupon?.discount">
            <span>優惠券折抵：</span>
            <span class="text-main">
              {{ getCurrentDiscount(currentCoupon?.discount) }}
            </span>
            <span>元</span>
          </div>
          <div class="col-12 p-2" v-else>
            <span>優惠券金額：</span>
            <span class="text-main">{{ currentCoupon?.extra_price || 0 }}</span>
            <span>元</span>
          </div>
        </div>
      </div>
    </div>

    <select-coupon
      v-if="currentPrice !== undefined"
      v-model:isOpen="isOpen"
      :kind="kind"
      v-model:currentCoupon="currentCoupon"
    />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import CouponItem from "@/components/CouponItem.vue";
import SelectCoupon from "./SelectCoupon.vue";
export default {
  name: "CartCoupon",
  components: {
    CouponItem,
    SelectCoupon,
  },
  props: {
    currentPrice: {
      type: Number,
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    kind: {
      type: Number,
      default: 1,
    },
    discountPrice: {
      type: [String, Number],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const currentCoupon = computed({
      get() {
        return props.modelValue;
      },
      set(newValue) {
        emit("update:modelValue", newValue || {});
      },
    });
    const handleSelectOpen = () => {
      isOpen.value = !isOpen.value;
    };

    const getCurrentDiscount = (value) => {
      if (props.discountPrice) {
        return "-$" + Math.abs(Math.round(props.discountPrice));
      }
      if (currentCoupon.value?.promotion_type === 0) {
        return "-$" + Math.round((props.currentPrice * (100 - value)) / 100);
      }
      return "-$" + value;
    };

    // watch(
    //   () => currentCoupon.value,
    //   () => {
    //     const newValue = currentCoupon.value?.extra_price
    //       ? currentCoupon.value?.extra_price
    //       : getCurrentDiscount(currentCoupon.value?.discount) * -1;
    //     emit("update:modelValue", newValue);
    //   }
    // );

    return {
      isOpen,
      handleSelectOpen,
      currentCoupon,
      getCurrentDiscount,
    };
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-border);

  .left {
    padding: 8px 24px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border: 1px solid var(--color-border);

    img {
      margin-right: 8px;
    }
  }

  .right {
    cursor: pointer;
    .right-icon {
      border: solid var(--color-border);
      border-width: 0px 3px 3px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(-45deg);
      margin-left: 8px;
    }
  }
}

.cart-coupon-content {
  border-bottom: 1px solid var(--color-border);
  margin-bottom: 8px;
}

.cart-coupon-block {
  min-height: 135px;
  /* border-bottom: 1px solid var(--color-border); */

  .cart-coupon-item {
    margin-top: 16px;
  }
}
</style>
